import React from 'react'
import styles from '../compare.module.scss'
import { policyAreas } from '../../../misc/ObservationHelpers'

export const ComparePolicyToggle = ({
  indicators,
  indicatorNameMap,
  isGraphView,
  setIsGraphView,
  policyForCharts,
  setPolicyForCharts,
}) => {
  return (
    <div className={styles.policySelect}>
      <div className={styles.selectLabel}>View by</div>
      <select
        className={styles.selectList}
        onChange={v => {
          // toggle to graph view if the policy viewed changes
          setPolicyForCharts(v.target.value)
          if (!isGraphView) {
            setIsGraphView(true)
          }
        }}
        value={policyForCharts}
      >
        <optgroup label="All">
          <option key={'All policies'} value={'All policies'}>
            All policies
          </option>
        </optgroup>
        <optgroup label="Policy category">
          {policyAreas.map(area => (
            <option key={area} value={area}>
              {area}
            </option>
          ))}
        </optgroup>
        <optgroup label="Specific policy">
          {indicators.map(d => (
            <option key={d} value={d}>
              {indicatorNameMap[d]}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}
export default ComparePolicyToggle
