/**
 * Graph view for displaying comparisons of
 * adoption level by country. Includes filters
 * for specific policies, countries and more.
 */

// standard packages
import React from 'react'
import { Link } from 'gatsby'

// import styles
import styles from './graphview.module.scss'

// local components
import { InfoTooltip, Table } from '../../common'
import NarrowInfo from '../../common/Styles/NarrowInfo'
import {
  sortByOverallAdoptionStatus,
  sortBySpecificAdoptionStatus,
  sortByAreaScore,
} from '../../misc/Util'

// import 3rd party packages
import classNames from 'classnames'

const GraphView = ({
  // data
  data,
  policy,
  policyName,
  policyAreaSelected,
}) => {
  // STATE // ---------------------------------------------------------------//

  // CONSTANTS // -----------------------------------------------------------//
  const allPolicyBins = [
    { name: 'VERY FEW' },
    { name: 'FEW' },
    { name: 'SOME' },
    { name: 'MANY' },
    { name: 'MOST' },
  ]
  const specificPolicyBins = [
    { name: 'NOT ADOPTED' },
    { name: 'PARTIAL' },
    { name: 'ADOPTED' },
  ]
  // define data field and column header
  let dataField, headerLabel
  if (policyAreaSelected) {
    dataField = 'areaScore'
    headerLabel = 'Policy adoption level: ' + policy
  } else if (policy === 'All policies') {
    dataField = 'adoptionScoreLevel'
    headerLabel = (
      <span>
        Policy adoption level{' '}
        <InfoTooltip
          place={'left'}
          text={
            <NarrowInfo>
              <strong>Policy adoption levels are determined as follows:</strong>
              <br />
              For all indicators for which there are data, the total of adopted
              (1) and partially adopted (0.5) is divided by the total number of
              indicators scored, then multiplied by 100 to create a continuous
              score. The table below reflects the qualitative score, defined by
              5 discrete benchmarks: ‘Very Few’ indicates less than 20% policy
              adoption rate; ‘Few’ is 20-39%; ‘Some’ is 40-59%; ‘Many’ is 60-79%
              adoption rate; and ‘Most’ indicates that 80% or more policies have
              been adopted.
            </NarrowInfo>
          }
        />
      </span>
    )
  } else {
    dataField = 'indicatorAdoptionScoreLevel'
    headerLabel = 'Policy adoption status: ' + policyName
  }
  // define columns for table
  // columns for overall and policy area
  const allPolicyColumns = [
    {
      header: 'Country',
      dataField: 'name',
      sort: true,
      formatter: function formatter(cell, row) {
        return (
          <div className={styles.countryCell}>
            <Link to={`/${row.iso2.toLowerCase()}`}>{cell}</Link>
            <img
              className={styles.flag}
              src={`https://flags.talusanalytics.com/shiny_100px/${row.iso2.toLowerCase()}.png`}
            ></img>
          </div>
        )
      },
    },
    {
      header: headerLabel,
      sort: true,
      dataField: policy === 'All policies' ? 'overall' : dataField,
      formatter: (cell, row) => {
        if (policyAreaSelected) {
          if (row.areaScore != null) {
            return <AdoptionCell level={row.areaScore.toLowerCase()} />
          } else {
            return <AdoptionCell level={null} />
          }
        } else {
          if (row.adoptionScoreLevel != null) {
            return <AdoptionCell level={row.adoptionScoreLevel.toLowerCase()} />
          } else {
            return <AdoptionCell level={null} />
          }
        }
      },
      sortFunc: (a, b, order, dataField, rowA, rowB, property = policy) => {
        if (policy === 'All policies') {
          return sortByOverallAdoptionStatus(a, b, order, dataField, rowA, rowB)
        } else {
          return sortByAreaScore(a, b, order, dataField, rowA, rowB, property)
        }
      },
    },
  ]

  //columns for specific policy indicator
  const specificPolicyColumns = [
    {
      header: 'Country',
      dataField: 'name',
      sort: true,
      formatter: function formatter(cell, row) {
        return (
          <div className={styles.countryCell}>
            <Link to={`/${row.iso2.toLowerCase()}`}>{cell}</Link>
            <img
              className={styles.flag}
              src={`https://flags.talusanalytics.com/shiny_100px/${row.iso2.toLowerCase()}.png`}
            ></img>
          </div>
        )
      },
    },
    {
      header: headerLabel,
      sort: true,
      dataField,
      formatter: (cell, row) => {
        if (row[dataField] != null) {
          return <SpecificAdoptionCell level={row[dataField].toLowerCase()} />
        } else {
          return <SpecificAdoptionCell level={null} />
        }
      },
      sortFunc: (a, b, order) => {
        return sortBySpecificAdoptionStatus(a, b, order)
      },
    },
  ]

  // EFFECT HOOKS // --------------------------------------------------------//

  // JSX
  let columns, bins
  if (policy == 'All policies') {
    columns = allPolicyColumns
    bins = allPolicyBins
  } else if (policyAreaSelected) {
    columns = allPolicyColumns
    bins = allPolicyBins
  } else {
    columns = specificPolicyColumns
    bins = specificPolicyBins
    // data = dummyData
  }
  return (
    <>
      <div
        className={classNames(
          styles.scaleContainer,
          policy == 'All policies' || policyAreaSelected
            ? null
            : styles.specificScaleContainer
        )}
      >
        <div className={styles.scaleLabel}>Policy adoption scale</div>
        <div
          className={
            policy == 'All policies' || policyAreaSelected
              ? styles.scale
              : styles.specificScale
          }
        >
          {bins.map(s => (
            <div
              key={s.name}
              className={classNames(
                styles.selected,
                s.name == 'NOT ADOPTED'
                  ? styles.notAdopted
                  : styles[s.name.toLowerCase().replace(' ', '')]
              )}
            >
              {s.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.graphView}>
        <Table
          {...{
            columns,
            data,
            ordering: ['name', 'asc'],
            className: 'blocks',
          }}
        />
      </div>
    </>
  )
}

function AdoptionCell({ level }) {
  if (level == null) {
    return (
      <div className={styles.noData}>
        <i>Data not available</i>
      </div>
    )
  } else
    return (
      <div className={styles.cellContainer}>
        <div
          className={classNames(
            styles.cell,
            styles.none,
            level == 'very few' ? styles.selected : null
          )}
        >
          VERY FEW
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.few,
            level == 'few' ? styles.selected : null,
            level == 'very few' ? styles.hide : null
          )}
        >
          FEW
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.some,
            level == 'some' ? styles.selected : null,
            ['very few', 'few'].includes(level) ? styles.hide : null
          )}
        >
          SOME
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.many,
            level == 'many' ? styles.selected : null,
            ['very few', 'few', 'some'].includes(level) ? styles.hide : null
          )}
        >
          MANY
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.most,
            level == 'most' ? styles.selected : null,
            level == 'very few' ||
              level == 'few' ||
              level == 'some' ||
              level == 'many'
              ? styles.hide
              : null
          )}
        >
          MOST
        </div>
      </div>
    )
}
//
// return (
//   <div className={styles.genericCellContainer}>
//     <div className={short ? styles.shortCellContainer : styles.cellContainer}>
//       <div className={classNames(styles.noDataCell, styles.first)}></div>
//       <div className={classNames(styles.noDataCell)}></div>
//       <div className={classNames(styles.noDataCell, styles.last)}></div>
//     </div>
//     <div className={styles.explainer}>
//       <i style={short ? { fontSize: '1rem' } : {}}>data not available</i>
//     </div>
//   </div>
// )

function SpecificAdoptionCell({ level }) {
  if (level == null) {
    return (
      <div className={styles.noData}>
        <i>Data not available</i>
      </div>
    )
  } else
    return (
      <div className={styles.specificCellContainer}>
        <div
          className={classNames(
            styles.cell,
            styles.notAdopted,
            level == 'not adopted' ? styles.selected : null
          )}
        >
          NOT ADOPTED
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.partial,
            level == 'partial' ? styles.selected : null
          )}
        >
          PARTIAL
        </div>
        <div
          className={classNames(
            styles.cell,
            styles.adopted,
            level == 'adopted' ? styles.selected : null
          )}
        >
          ADOPTED
        </div>
      </div>
    )
}

export default GraphView
