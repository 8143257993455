import React from 'react'
import { Link } from 'gatsby'
import styles from './MobileRedirect.module.scss'

/**
 * On small screens, show mobile redirect message linking to map page on pages
 * that are too large.
 */
export const MobileRedirect = () => {
  return (
    <div className={styles.mobileRedirect}>
      <p>
        This page is currently only viewable on larger screens. Please return
        using a desktop browser, or maximize your browser window, and content
        will appear.
      </p>
      <Link to="/map/">
        <button>Go to map page</button>
      </Link>
    </div>
  )
}

export default MobileRedirect
