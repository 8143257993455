import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Router } from '@reach/router'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Compare from '../components/views/Compare/Compare'

export default function CompareTemplate({
  pageContext: {
    globalAvg,
    regionalAvg,
    epiData,
    placeDataByIso2,
    indicatorNameMap,
  },
}) {
  const [page, setPage] = useState(null)
  return (
    <Layout page={page}>
      <SEO
        //TODO: change this to be the actual name of the country?
        title="Compare countries worldwide"
        description="Overview of HIV policies for countries worldwide"
      />
      <Compare
        {...{
          page,
          setPage,
          globalAvg,
          regionalAvg,
          placeDataByIso2,
          indicatorNameMap,
          epiData,
        }}
      />
    </Layout>
  )
}
