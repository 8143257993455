import React from 'react'
import { FilterSet } from '../../../common'

export const CompareFilters = ({
  filters,
  setFilters,
  dataForGraphAndTable,
  policyAreaSelected,
  policyForCharts,
  regionalAvg,
}) => {
  return (
    <FilterSet
      {...{
        filterDefs: [
          {
            areaScore: {
              field: 'areaScore',
              label: 'Area adoption level',
              skip: !policyAreaSelected,
              items: ['Most', 'Many', 'Some', 'Few', 'Very few'].map(name => {
                return {
                  value: name,
                  label: name,
                  id: name,
                }
              }),
            },
            adoptionScoreLevel: {
              field: 'adoptionScoreLevel',
              label: 'Overall policy adoption level',
              skip: policyForCharts !== 'All policies',
              items: ['Most', 'Many', 'Some', 'Few', 'Very few'].map(name => {
                return {
                  value: name,
                  label: name,
                  id: name,
                }
              }),
            },
            indicatorAdoptionScoreLevel: {
              field: 'indicatorAdoptionScoreLevel',
              label: 'Policy adoption status',
              skip: policyForCharts === 'All policies' || policyAreaSelected,
              items: [
                ['Adopted', 'adopted'],
                ['Partially adopted', 'partial'],
                ['Not adopted', 'not adopted'],
              ].map(([label]) => {
                return {
                  value: label,
                  label,
                  id: label,
                }
              }),
            },
            region: {
              field: 'region',
              label: 'UNAIDS region',
              items: Object.keys(regionalAvg)
                .filter(d => d !== 'Unspecified region')
                .sort()
                .map(name => {
                  return { value: name, label: name, id: name }
                }),
            },
          },
          {
            name: {
              field: 'name',
              label: 'Country',
              items: dataForGraphAndTable
                .filter(d => {
                  if (filters['region'] !== undefined) {
                    return filters['region'].includes(d.region)
                  } else return true
                })
                .map(({ name }) => {
                  return { value: name, label: name, id: name }
                }),
            },
            is_pepfar: {
              field: 'is_pepfar',
              label: 'PEPFAR country?',
              items: [
                { value: 'Yes', label: 'Yes', id: true },
                { value: 'No', label: 'No', id: false },
              ],
            },
          },
        ],
        filters,
        setFilters,
      }}
    />
  )
}

export default CompareFilters
