import {
  labelBinScale,
  capLabelBinScale,
} from '../../common/MapboxMap/plugins/data'
import { getDisplayNameFromSlug } from '../../misc/ObservationHelpers'
import { getInitCap, isNullOrUndef } from '../../misc/Util'

/**
 * Returns policy indicator status levels and/or adoption counts by country for
 * display in the graph / table components defined in `GraphView` and
 * `TableView`, respectively.
 *
 * Format of output, one object per country:
 * [
   {
     name: "Albania",
     Clinical and treatment: 2,
     Health systems: 2,
     Structural: 6,
     Testing and prevention: 4,
     adoptionScoreLevel: "Many",
     display: true,
     iso2: "AL",
     overall: 14,
     region: "Eastern Europe and central Asia",
     total: 35
   },
   ...
 ]
 * @method getData
 * @param  {[type]} year               [description]
 * @param  {[type]} filters            [description]
 * @param  {[type]} placeDataByIso2      [description]
 * @param  {[type]} policyForCharts    [description]
 * @param  {[type]} policyAreaAdoption [description]
 * @param  {[type]} }                  [description]
 * @return {[type]}                    [description]
 */
export const getData = ({
  year,
  filters,
  placeDataByIso2,
  policyForCharts,
  policyAreaAdoption,
  policyAreaSelected,
}) => {
  const dataForGraphAndTable = []
  policyAreaAdoption.forEach(d => {
    const row = {
      region: d.region,
      name: d.name,
      iso2: d.iso2,
      display: true,
      is_pepfar: placeDataByIso2[d.iso2].is_pepfar,
    }

    // join indicator data, if applicable
    if (policyForCharts !== 'All policies') {
      const hasValue =
        placeDataByIso2[d.iso2].indScores[year] !== undefined &&
        placeDataByIso2[d.iso2].indScores[year][policyForCharts] !== undefined
      const raw = hasValue
        ? placeDataByIso2[d.iso2].indScores[year][policyForCharts].value
        : null
      if (!hasValue) {
        row.indicatorAdoptionScoreLevel = null
      } else {
        if (raw === 0) row.indicatorAdoptionScoreLevel = 'not adopted'
        else if (raw === 0.5) row.indicatorAdoptionScoreLevel = 'partial'
        else if (raw === 1) row.indicatorAdoptionScoreLevel = 'adopted'
        else if (raw === null) row.indicatorAdoptionScoreLevel = null
      }
    }

    let areaScore
    if (policyAreaSelected) {
      const hasValue =
        placeDataByIso2[d.iso2].policyAreaScores[year] !== undefined &&
        placeDataByIso2[d.iso2].policyAreaScores[year][policyForCharts] !==
          undefined
      const aScore = hasValue
        ? placeDataByIso2[d.iso2].policyAreaScores[year][policyForCharts].value
        : null
      if (!hasValue) {
        row.areaScore = null
      } else {
        row.areaScore = capLabelBinScale(aScore)
      }
      areaScore = capLabelBinScale(aScore)
    }

    // calculate adoption score level
    const hasScore =
      placeDataByIso2[d.iso2].overall[year] !== undefined &&
      placeDataByIso2[d.iso2].overall[year] !== null
    const score = hasScore ? placeDataByIso2[d.iso2].overall[year].value : null
    const adoptionScoreLevel =
      score !== null ? getInitCap(labelBinScale(score).toLowerCase()) : null
    row.adoptionScoreLevel = adoptionScoreLevel

    // apply filters // ---------------------------------------------------- //
    for (const [field, allowedValues] of Object.entries(filters)) {
      if (field === 'areaScore') {
        const skip = !policyAreaSelected
        if (skip) continue
        else if (!allowedValues.includes(areaScore)) {
          row.display = false
        }
      } else if (field === 'adoptionScoreLevel') {
        const skip = policyForCharts !== 'All policies'
        if (skip) continue
        else if (!allowedValues.includes(adoptionScoreLevel))
          row.display = false
      } else if (field === 'is_pepfar') {
        if (row.is_pepfar && !allowedValues.includes('Yes')) row.display = false
        if (!row.is_pepfar && !allowedValues.includes('No')) row.display = false
        continue
      } else if (field === 'indicatorAdoptionScoreLevel') {
        const skip = policyForCharts === 'All policies'
        const value =
          getDisplayNameFromSlug(row.indicatorAdoptionScoreLevel) || 'null'
        if (skip) continue
        else if (!allowedValues.includes(value)) row.display = false
      } else if (!allowedValues.includes(d[field])) row.display = false
    }

    // country overall policies  adopted, and the total that
    // had data (denominator)
    if (d[year] === undefined) {
      row['Clinical and treatment'] = null
      row['Testing and prevention'] = null
      row['Structural'] = null
      row['Health systems'] = null
      row.overall = null
      row.total = null
    } else
      for (const [area, levels] of Object.entries(d[year])) {
        const noData =
          (isNullOrUndef(levels.adopted) &&
            isNullOrUndef(levels.partial) &&
            isNullOrUndef(levels['not adopted'])) ||
          Object.keys(levels).length === 0

        if (noData) {
          row[area] = null //translates to "No data" score in table
        } else if (levels.adopted === null && levels.partial === null) {
          row[area] = 0
        } else {
          // Partial scores count as 0.5, adopted scores count as 1
          const areaCount = (levels.adopted || 0) + (levels.partial * 0.5 || 0)
          row[area] = areaCount
          if (row.overall === undefined) {
            row.overall = areaCount
          } else {
            row.overall += areaCount
          }
        }
        row.total = 33
      }
    dataForGraphAndTable.push(row)
  })
  return dataForGraphAndTable
}
