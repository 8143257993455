import React from 'react'
import styles from '../compare.module.scss'

import CompareFilters from './CompareFilters'
import CompareChartToggle from './CompareChartToggle'
import ComparePolicyToggle from './ComparePolicyToggle'
import CompareDownloadBtns from './CompareDownloadBtns'
import TableView from '../../../compare/TableView/TableView'
import GraphView from '../../../compare/GraphView/GraphView'
export const CompareGraphAndTable = ({
  chartTitle,
  displayYear,
  year,
  indicators,
  indicatorNameMap,
  filters,
  filteredData,
  allPoliciesSelected,
  policyAreaSelected,
  singlePolicySelected,
  isGraphView,
  downloadBtnLabel,
  setDownloadBtnLabel,
  setIsGraphView,
  policyForCharts,
  setPolicyForCharts,
  setFilters,
  dataForGraphAndTable,
  regionalAvg,
}) => {
  return (
    <div className={styles.chartContainer}>
      <h1>
        {chartTitle}
        <CompareDownloadBtns
          {...{
            year,
            filters,
            filteredData,
            policyForCharts,
            allPoliciesSelected,
            policyAreaSelected,
            singlePolicySelected,
            isGraphView,
            downloadBtnLabel,
            setDownloadBtnLabel,
          }}
        />
      </h1>
      <div className={styles.chartHeader}>
        <ComparePolicyToggle
          {...{
            indicators,
            indicatorNameMap,
            isGraphView,
            setIsGraphView,
            policyForCharts,
            setPolicyForCharts,
          }}
        />
        <CompareChartToggle
          {...{
            toggleGraphView: () => {
              if (!isGraphView) {
                setIsGraphView(true)
              }
            },
            toggleTableView: () => {
              if (isGraphView) {
                setIsGraphView(false)
              }
            },
            policyForCharts,
            setPolicyForCharts,
            isGraphView,
          }}
        />
      </div>
      {
        // Define filters for graph and table
      }
      <CompareFilters
        {...{
          filters,
          setFilters,
          dataForGraphAndTable,
          policyAreaSelected,
          policyForCharts,
          regionalAvg,
        }}
      />
      {
        // Show graph or table view of data (toggleable)
      }
      {isGraphView ? (
        <GraphView
          data={filteredData}
          policy={policyForCharts}
          policyName={
            policyAreaSelected
              ? policyForCharts
              : indicatorNameMap[policyForCharts]
          }
          policyAreaSelected={policyAreaSelected}
          indicators={indicators}
          displayYear={displayYear}
        />
      ) : (
        <TableView data={filteredData} year={year} displayYear={displayYear} />
      )}
    </div>
  )
}
export default CompareGraphAndTable
