import React from 'react'
import classNames from 'classnames'
import styles from '../compare.module.scss'

export const CompareChartToggle = ({
  toggleGraphView,
  toggleTableView,
  policyForCharts,
  setPolicyForCharts,
  isGraphView,
}) => {
  return (
    <div className={styles.chartToggles}>
      <div
        className={classNames(
          styles.graphToggle,
          isGraphView ? styles.selected : null
        )}
        onClick={() => toggleGraphView()}
      >
        Graphic
      </div>
      <div
        className={classNames(
          styles.tableToggle,
          isGraphView ? null : styles.selected
        )}
        // if specific policy or policy category is selected,
        // change to 'All policies' and then toggle Table view
        onClick={
          policyForCharts === 'All policies'
            ? () => toggleTableView()
            : () => {
                setPolicyForCharts('All policies')
                toggleTableView()
              }
        }
      >
        Table
      </div>
    </div>
  )
}
export default CompareChartToggle
