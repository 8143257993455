/**
 * Table view for displaying comparisons of
 * adoption level by country. Includes filters
 * for specific policies, countries and more.
 */

// standard packages
import React, { useEffect } from 'react'

// import styles
import styles from './tableview.module.scss'
import infoIcon from '../../../assets/icons/info.svg'

// import 3rd party packages
import { Link } from 'gatsby'
import ReactTooltip from 'react-tooltip'

// local components
import { Table } from '../../common'
import NarrowInfo from '../../common/Styles/NarrowInfo'
import { renderToString } from 'react-dom/server'

// utility functions

export const TableView = ({
  // data
  data,
  year,
  displayYear,
}) => {
  // STATE // ---------------------------------------------------------------//

  // CONSTANTS // -----------------------------------------------------------//
  // define columns for table
  const columns = [
    {
      header: 'Country',
      dataField: 'name',
      sort: true,
      formatter: function getCountryLink(cell, row) {
        return <Link to={`/${row.iso2.toLowerCase()}`}>{cell}</Link>
      },
    },
    {
      header: 'Overall adoption',
      sort: true,
      dataField: 'overall',
      formatter: function getCellValue(_cell, row) {
        if (row.overall !== undefined && row.total !== null)
          return `${row.overall}/${row.total}`
        else return <span className={styles.unspecified}>{'No data'}</span>
      },
    },
  ].concat(
    [
      'Clinical and treatment',
      'Testing and prevention',
      'Structural',
      'Health systems',
    ].map(header => {
      return {
        header,
        dataField: header,
        sort: true,
      }
    })
  )

  //define content of tooltip
  const dataTip = renderToString(
    <NarrowInfo>
      The total number of policies that are fully adopted (counted as 1.0) and
      partially adopted (counted as 0.5), overall and by policy category. See{' '}
      <Link to="/methods">methods</Link> for additional details.
    </NarrowInfo>
  )

  // UTILITY FUNCTIONS // ---------------------------------------------------//

  // EFFECT HOOKS // --------------------------------------------------------//
  // when table is rendered, append additional header row
  useEffect(() => {
    const thSpanExisting = document.getElementById('bandedTableThSpan')
    //create info icon
    const span = document.createElement('span')
    const img = document.createElement('img')
    img.src = infoIcon
    img.style.width = '14px'
    img.style.position = 'relative'
    img.style.marginLeft = '10px'
    img.setAttribute('data-tip', dataTip)
    img.setAttribute('data-for', 'table-tip')
    img.setAttribute('data-html', true)
    span.appendChild(img)
    if (thSpanExisting) {
      thSpanExisting.innerText =
        'Policy adoption by category ' + `(${displayYear})`
      thSpanExisting.appendChild(span)
    } else {
      const el = document.getElementById('bandedTable').firstChild
      const tr = document.createElement('tr')
      const th = document.createElement('th')
      const th2 = document.createElement('th')
      const thSpan = document.createElement('th')
      thSpan.colSpan = 4
      thSpan.innerText = 'Policy adoption by category ' + `(${displayYear})`
      thSpan.id = 'bandedTableThSpan'
      thSpan.appendChild(span)
      tr.appendChild(th)
      tr.appendChild(th2)
      tr.appendChild(thSpan)
      el.insertBefore(tr, el.firstChild)
      ReactTooltip.rebuild()
    }
  }, [year])

  // JSX
  return (
    <div className={styles.tableView}>
      <Table
        {...{
          columns,
          data,
          ordering: ['name', 'asc'],
          className: 'banded',
          id: 'bandedTable',
        }}
      />
      <ReactTooltip
        className={styles.tipContainer}
        type="light"
        id="table-tip"
        clickable={true}
        place="left"
        effect="solid"
        delayHide={250}
      />
    </div>
  )
}

export default TableView
