/**
 * Returns the slugs of tabs to export in the Excel download given the current
 * Compare page selections.
 *
 * @param {boolean} exportScoresThenCounts True if the scores then counts
 * should be exported in Excel, false if other way around.
 *
 * @param {boolean} exportValues True if an individual policy, e.g., CT4, has
 * been selected, false if a policy category or all policies have been
 *
 * @returns {string[]} The slug values of tabs that should be downloaded
 */
export function getTabsToExport(exportScoresThenCounts, exportValues) {
  if (exportValues) return ['value']
  else if (exportScoresThenCounts) return ['score', 'count']
  else return ['count', 'score']
}
