// standard packages
import React, { useState, useEffect, useRef } from 'react'

// import styles
import styles from './compare.module.scss'
import globe from '../../../assets/images/globe.png'

// import 3rd party packages
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

// import components
import CompareGraphAndTable from './content/CompareGraphAndTable'
import MobileRedirect from './content/MobileRedirect/MobileRedirect'
import CountryHeader from '../../common/CountryHeader/CountryHeader'
import CountryOverview from '../../country/CountryOverview/CountryOverview'
import HivOverview from '../../country/HivOverview/HivOverview'
import AdoptionOverview from '../../country/AdoptionOverview/AdoptionOverview'
import PolicyAreaComparisons from '../../country/PolicyAreaComparisons/PolicyAreaComparisons'
import { StickyHeader } from '../../common'

// utility functions
import { getDisplayYear } from '../../misc/Util'
import { getData } from './CompareHelpers'
import {
  getNumberIndicators,
  getOverallScores,
  getPolicyAreaScores,
  getPolicyAreaAdoptionCounts,
} from '../../misc/ObservationHelpers'

const Compare = ({
  // set page function allows current page to be set so nav emphasis is applied
  epiData,
  globalAvg,
  indicatorNameMap,
  placeDataByIso2,
  regionalAvg,
  setPage,
}) => {
  // STATE // ---------------------------------------------------------------//
  // display mini header on page scroll using these state variables
  const [displayMiniHeader, setDisplayMiniHeader] = useState(false)
  const [simpleHeaderRef, setSimpleHeaderRef] = useState({ current: null })
  const [, setHeaderHeight] = useState(null)
  let headerRef = useRef(null)

  // define current year of data to show for all charts
  const [year, setYear] = useState('recent')

  // true if main component has initially mounted, false otherwise; required
  // for proper rehydration of React in live site
  const [hasMounted, setHasMounted] = useState(false)

  // filters control what data are shown in graph/table and downloaded as Excel
  const [filters, setFilters] = useState({})

  // for toggling the graph or table view
  const [isGraphView, setIsGraphView] = useState(false)

  // control what data are shown in the charts (all, category, specific, etc.)
  const [policyForCharts, setPolicyForCharts] = useState('All policies')

  // control label in download button; changes to "Downloading..."
  const [downloadBtnLabel, setDownloadBtnLabel] = useState('Download data')

  // CONSTANTS // -----------------------------------------------------------//
  // quick reference for if a policy area is selected in bottom chart, etc.
  const allPoliciesSelected = policyForCharts === 'All policies'
  const policyAreaSelected =
    policyForCharts === 'Clinical and treatment' ||
    policyForCharts === 'Testing and prevention' ||
    policyForCharts === 'Structural' ||
    policyForCharts === 'Health systems'
  const singlePolicySelected = !allPoliciesSelected && !policyAreaSelected

  // get title for chart section
  let chartTitle
  if (isGraphView) {
    if (allPoliciesSelected) {
      chartTitle = 'Global comparison by country'
    } else if (policyAreaSelected) {
      chartTitle = `${policyForCharts} adoption by country`
    } else {
      const code = policyForCharts.split(' - ')[0]
      chartTitle = `Policy ${code} adoption by country`
    }
  } else {
    chartTitle = 'Global comparison by country'
  }

  // list of indicators
  const context = Object.values(placeDataByIso2)
  const indicators = Object.keys(context[0].indScores[2019])

  // get number of indicators and scores to display in charts
  const overallScores = getOverallScores(context) // bar chart
  const policyAreaScores = getPolicyAreaScores(context) // bullet chart
  const numApplicableIndicators = getNumberIndicators() // table
  const policyAreaAdoption = getPolicyAreaAdoptionCounts(context) // table

  // get data for display in `GraphView` and `TableView` components below.
  // Format of output of `getData` function, one object per country:
  // [
  //   {
  //     name: "Albania",
  //     Clinical and treatment: 2,
  //     Health systems: 2,
  //     Structural: 6,
  //     Testing and prevention: 4,
  //     adoptionScoreLevel: "Many",
  //     display: true,
  //     iso2: "AL",
  //     overall: 14,
  //     region: "Eastern Europe and central Asia",
  //     total: 35
  //   },
  //   ...
  // ]
  const dataForGraphAndTable = getData({
    filters,
    placeDataByIso2,
    policyAreaAdoption,
    policyAreaSelected,
    policyForCharts,
    year,
  })

  // for display keep only data that matched the filters; note that `display`
  // is defined in `getData`
  const filteredData = dataForGraphAndTable.filter(d => d.display)

  // EFFECT HOOKS // --------------------------------------------------------//
  useEffect(() => {
    setPage('compare')
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (simpleHeaderRef.current !== null) {
      setHeaderHeight(simpleHeaderRef.current.getBoundingClientRect().height)
      window.addEventListener('resize', () => {
        if (headerRef.current !== null) {
          setHeaderHeight(
            simpleHeaderRef.current.getBoundingClientRect().height
          )
        }
      })
      window.addEventListener('scroll', () => {
        if (headerRef.current !== null) {
          const bcr = headerRef.current.getBoundingClientRect()
          const displayThresh = bcr.top + bcr.height
          setDisplayMiniHeader(window.scrollY > displayThresh)
        }
      })
    }
  }, [simpleHeaderRef])

  // JSX // ---------------------------------------------------------------- //
  if (!hasMounted) return null
  else
    return (
      <>
        <article className={styles.main}>
          <CountryHeader
            {...{ name: 'Global progress', year, setYear, comparePage: true }}
          />
          <StickyHeader
            {...{
              name: 'Global',
              setSimpleHeaderRef,
              setYear,
              show: displayMiniHeader,
              year,
              img: (
                <img
                  style={{ height: '45px', marginTop: '5px' }}
                  src={globe}
                ></img>
              ),
            }}
          />
          {/* Global version of typical country page header */}
          <header ref={headerRef} className={styles.header}>
            <div className={styles.gridBox}>
              <CountryOverview
                overallScore={globalAvg[year].policyAreaScores.all}
                overallYear={year}
                comparePage={true}
              />
            </div>
            <div className={styles.gridBox}>
              <AdoptionOverview
                {...{
                  numApplicableIndicators,
                  curYearPolicyAreaAdoption: globalAvg[year].adoptionCount,
                  overallYear: year,
                  comparePage: true,
                }}
              />
            </div>
            <div className={classNames(styles.gridBox, styles.lastGridBox)}>
              <HivOverview year={year} epiData={epiData} />
            </div>
          </header>
          <PolicyAreaComparisons
            isGlobal
            {...{
              globalAvg,
              overallScores,
              placeDataByIso2,
              policyAreaAdoption,
              policyAreaScores,
              regionalAvg,
              year,
            }}
          />
          <CompareGraphAndTable
            displayYear={getDisplayYear(year)}
            {...{
              allPoliciesSelected,
              chartTitle,
              dataForGraphAndTable,
              downloadBtnLabel,
              filteredData,
              filters,
              indicatorNameMap,
              indicators,
              isGraphView,
              policyAreaSelected,
              policyForCharts,
              regionalAvg,
              setDownloadBtnLabel,
              setFilters,
              setIsGraphView,
              setPolicyForCharts,
              singlePolicySelected,
              year,
            }}
          />
        </article>
        <ReactTooltip
          className={styles.tipContainer}
          effect="solid"
          id="statusTip"
          place="left"
          type="light"
        />
        <ReactTooltip type="light" id="simpleTip" place="top" effect="solid" />
        <MobileRedirect />
      </>
    )
}

export default Compare
