import React from 'react'
import { Export } from '../../../misc/Queries'
import { PrimaryButton } from '../../../common'
import { getValueFromDisplayName } from '../../../misc/ObservationHelpers'
import { getTabsToExport } from '../../../misc/QueriesHelpers'

export const CompareDownloadBtns = ({
  year,
  filters,
  filteredData,
  policyForCharts,
  allPoliciesSelected,
  policyAreaSelected,
  singlePolicySelected,
  isGraphView,
  downloadBtnLabel,
  setDownloadBtnLabel,
}) => {
  return (
    <>
      <PrimaryButton
        isSecondary
        {...{
          label: 'Go to data access page',
          url: '/data',
          urlIsExternal: false,
        }}
      />
      <PrimaryButton
        {...{
          label: downloadBtnLabel,
          disabled:
            filteredData.length === 0 || downloadBtnLabel === 'Downloading...',
          onClick: () => {
            // define whether different types of filters should be
            // applied based on the currently visible data
            const applyPolicyValueFilter =
              filters['indicatorAdoptionScoreLevel'] !== undefined &&
              singlePolicySelected

            const applyPolicyAreaScoreFilter =
              filters['areaScore'] !== undefined && policyAreaSelected

            const applyOverallScoreFilter =
              filters['adoptionScoreLevel'] !== undefined && allPoliciesSelected

            setDownloadBtnLabel('Downloading...')
            // Export the data
            Export({
              filters: {
                ...filters,
                name: undefined,
                indicatorAdoptionScoreLevel: undefined,
                adoptionScoreLevel: applyOverallScoreFilter
                  ? filters['adoptionScoreLevel']
                  : undefined,
                areaScore: applyPolicyAreaScoreFilter
                  ? filters['areaScore']
                  : undefined,
                value: applyPolicyValueFilter
                  ? filters['indicatorAdoptionScoreLevel'].map(
                      getValueFromDisplayName
                    )
                  : undefined,
                year: [year],
                'place.name': [...new Set(filteredData.map(d => d.name))],
                area: policyAreaSelected ? [policyForCharts] : undefined,
                title: singlePolicySelected
                  ? [policyForCharts.split(' - ')[0]]
                  : undefined,
              },
              // filters: { 'place.name': ['Albania'], year: [2018] },
              tabs: getTabsToExport(isGraphView, singlePolicySelected),
            })
              .then(() => setDownloadBtnLabel('Download data'))
              .catch(e => {
                setDownloadBtnLabel('Download data')
                alert('An error occured while downloading data: ' + e)
              })
          },
        }}
      />
    </>
  )
}
export default CompareDownloadBtns
